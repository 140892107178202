import * as React from "react";
import {
  FormFieldProps,
  Header,
  Input,
  InputProps,
  SelectProps,
  SpaceBetween,
  Toggle,
} from "@amzn/awsui-components-react";
import { FieldConfiguration } from "@amzn/ask-legal-domain";
import { FieldDefinition } from "@amzn/altar-sds-client";
import { StaticListSelect } from "../../../../common/StaticListSelect";
import { AdvancedListConstants } from "../../../../../utils/advanced-list.constant";
import { UIField } from "../../../../common/UIField";

export const DateFieldAddEdit = (props: {
  value?: {
    fieldDef?: FieldDefinition;
    fieldVisibility?: boolean;
    fieldConfiguration?: FieldConfiguration.Date;
  };
  onUpdated?: (value?: {
    fieldDef?: FieldDefinition;
    fieldVisibility?: boolean;
    fieldConfiguration?: FieldConfiguration.Date;
  }) => void;
  onCancel?: () => void;
}) => {
  const [fieldColumnDisplayName, setFieldColumnDisplayName] =
    React.useState<FormFieldProps>({});
  const [columnDisplayName, setColumnDisplayName] = React.useState<InputProps>({
    value: props.value?.fieldDef?.displayName ?? "",
  });
  const [showOnLivePage, setShowOnLivePage] = React.useState<boolean>(true);

  const [fieldDateFormat, setFieldDateFormat] = React.useState<FormFieldProps>(
    {}
  );

  const [dateFieldConfig, setDateFieldConfig] =
    React.useState<FieldConfiguration.Date>({
      dateFormat:
        props.value?.fieldConfiguration?.dateFormat ??
        AdvancedListConstants.DEFAULT_DATE_FORMAT,
    });

  function validate() {
    let valid = true;
    if (columnDisplayName.value?.length > 0) {
      setFieldColumnDisplayName({
        errorText: "",
      });
    } else {
      setFieldColumnDisplayName({
        errorText: "Column Display Name is required",
      });
      valid = false;
    }

    if (dateFieldConfig?.dateFormat?.trim().length > 0) {
      setFieldDateFormat({
        errorText: "",
      });
    } else {
      setFieldDateFormat({
        errorText: "Date format selection is required",
      });
      valid = false;
      return;
    }

    if (valid) {
      props.onUpdated?.({
        fieldDef: {
          ...props.value.fieldDef,
          displayName: columnDisplayName.value!,
        },
        fieldConfiguration: {
          ...props.value.fieldConfiguration,
          dateFormat: dateFieldConfig.dateFormat,
        },
        fieldVisibility: showOnLivePage,
      });
    }
  }

  React.useEffect(() => {
    validate();
  }, [columnDisplayName.value, showOnLivePage, dateFieldConfig.dateFormat]);

  React.useEffect(() => {
    if (!props.value?.fieldDef) return;
    setColumnDisplayName({ value: props.value.fieldDef.displayName });
    setDateFieldConfig({
      dateFormat:
        props.value?.fieldConfiguration?.dateFormat ??
        AdvancedListConstants.DEFAULT_DATE_FORMAT,
    });
    setShowOnLivePage(props.value?.fieldVisibility);
  }, [props.value]);

  return (
    <SpaceBetween direction="vertical" size="m">
      <SpaceBetween direction="vertical" size="xxs">
        <Header headingTagOverride="h4">Date Column Details</Header>
        <UIField.CustomField
          name={"Column Display Name"}
          description={"How should column to be displayed on live page"}
          errorText={fieldColumnDisplayName.errorText}
          child={
            <Input
              value={columnDisplayName.value}
              onChange={(e) => setColumnDisplayName({ value: e.detail.value })}
              placeholder={"Enter Column Name"}
            />
          }
        />

        <UIField.CustomField
          name="Date Format"
          errorText={fieldDateFormat.errorText}
          child={
            <StaticListSelect.Single<string>
              data={AdvancedListConstants.DISPLAY_DATE_FORMATS}
              selected={dateFieldConfig.dateFormat}
              onSelectChange={(format: string) =>
                setDateFieldConfig({
                  dateFormat: format,
                })
              }
              fromOption={(option: SelectProps.Option) => {
                return option.value;
              }}
              toOption={(value: string) => {
                return { label: value, value: value };
              }}
            />
          }
        />
      </SpaceBetween>

      <SpaceBetween direction="vertical" size="xxs">
        <Header headingTagOverride="h4">Options</Header>
        <SpaceBetween size="s" direction="vertical">
          <Toggle
            checked={showOnLivePage}
            onChange={({ detail }) => {
              setShowOnLivePage(detail.checked);
            }}
          >
            Show column on live page
          </Toggle>
        </SpaceBetween>
      </SpaceBetween>
    </SpaceBetween>
  );
};
