import * as React from "react";
import {
  Box,
  FormFieldProps,
  Header,
  Input,
  InputProps,
  SelectProps,
  SpaceBetween,
  Toggle,
} from "@amzn/awsui-components-react";
import {
  Currency,
  FieldConfiguration,
} from "@amzn/ask-legal-domain";
import { FieldDefinition } from "@amzn/altar-sds-client";
import { StaticListSelect } from "../../../../common/StaticListSelect";
import { NumberFormatUtil } from "../../../../../utils/advanced-list.constant";
import { UIField } from "../../../../common/UIField";

export const NumberFieldAddEdit = (props: {
  value?: {
    fieldDef?: FieldDefinition;
    fieldVisibility?: boolean;
    fieldConfiguration?: FieldConfiguration.Number;
  };
  onUpdated?: (value?: {
    fieldDef?: FieldDefinition;
    fieldVisibility?: boolean;
    fieldConfiguration?: FieldConfiguration.Number;
  }) => void;
  onCancel?: () => void;
}) => {
  const [fieldColumnDisplayName, setFieldColumnDisplayName] =
    React.useState<FormFieldProps>({});
  const [columnDisplayName, setColumnDisplayName] = React.useState<InputProps>({
    value: props.value?.fieldDef?.displayName ?? "",
  });
  const [showOnLivePage, setShowOnLivePage] = React.useState<boolean>(true);

  const [fieldNumberFormat, setFieldNumberFormat] =
    React.useState<FormFieldProps>({});

  const [fieldNumberCurrency, setFieldNumberCurrency] =
    React.useState<FormFieldProps>({});

  // number field configurations
  const [numberFieldConfig, setNumberFieldConfig] = React.useState<
    FieldConfiguration.Number & { displayAsCurrency?: boolean }
  >({
    format:
      props.value?.fieldConfiguration?.format ??
      NumberFormatUtil.DEFAULT_NUMBER_FORMAT.format,
    displayAsCurrency: props.value?.fieldConfiguration?.currency ? true : false,
    currency: props.value?.fieldConfiguration?.currency,
  });

  function validate() {
    let valid = true;

    if (columnDisplayName.value?.length > 0) {
      setFieldColumnDisplayName({
        errorText: "",
      });
    } else {
      setFieldColumnDisplayName({
        errorText: "Column Display Name is required",
      });
      valid = false;
    }

    if (numberFieldConfig?.format?.trim().length > 0) {
      setFieldNumberFormat({
        errorText: "",
      });
    } else {
      setFieldNumberFormat({
        errorText: "Number format selection is required",
      });
      valid = false;
    }

    if (!numberFieldConfig.displayAsCurrency) {
      setFieldNumberCurrency({
        errorText: "",
      });
    }
    if (
      numberFieldConfig.displayAsCurrency &&
      numberFieldConfig.currency!.trim().length > 0
    ) {
      setFieldNumberCurrency({
        errorText: "",
      });
    }

    if (
      numberFieldConfig.displayAsCurrency &&
      numberFieldConfig.currency?.trim().length <= 0
    ) {
      setFieldNumberCurrency({
        errorText: "Currency selection is required",
      });
      valid = false;
    }

    if (valid) {
      props.onUpdated?.({
        fieldDef: {
          ...props.value?.fieldDef,
          displayName: columnDisplayName.value!,
        },
        fieldConfiguration: {
          ...props.value?.fieldConfiguration,
          format: numberFieldConfig.format,
          currency: numberFieldConfig.currency,
        },
        fieldVisibility: showOnLivePage,
      });
    }
  }

  React.useEffect(() => {
    validate();
  }, [
    columnDisplayName.value,
    numberFieldConfig.format,
    numberFieldConfig.currency,
  ]);

  React.useEffect(() => {
    if (!props.value?.fieldDef) return;
    setColumnDisplayName({ value: props.value.fieldDef.displayName });

    setNumberFieldConfig({
      format:
        props.value.fieldConfiguration?.format ??
        NumberFormatUtil.DEFAULT_NUMBER_FORMAT.format,
      displayAsCurrency: props.value?.fieldConfiguration?.currency
        ? true
        : false,
      currency: props.value?.fieldConfiguration?.currency,
    });

    setShowOnLivePage(props.value?.fieldVisibility);
  }, [props.value]);

  const handleDisplayAsCurrency = (checked: boolean) => {
    if (checked) {
      setNumberFieldConfig((prev) => ({
        ...prev,
        displayAsCurrency: true,
        currency: props.value?.fieldConfiguration?.currency ?? Currency.Code.USD,
      }));
    } else {
      setNumberFieldConfig((prev) => ({
        ...prev,
        displayAsCurrency: false,
        currency: undefined,
      }));
    }
  };

  return (
    <SpaceBetween direction="vertical" size="m">
      <SpaceBetween direction="vertical" size="xxs">
        <Header headingTagOverride="h4">Number Column Details</Header>
        <UIField.CustomField
          name={"Column Display Name"}
          description={"How should column to be displayed on live page"}
          errorText={fieldColumnDisplayName.errorText}
          child={
            <Input
              value={columnDisplayName.value}
              onChange={(e) => setColumnDisplayName({ value: e.detail.value })}
              placeholder={"Enter Column Name"}
            />
          }
        />

        <UIField.CustomField
          name={`Number Format`}
          description={`Please select desired number format`}
          errorText={fieldNumberFormat.errorText}
          child={
            <StaticListSelect.Single<NumberFormatUtil.NumberFormat>
              data={NumberFormatUtil.AVAILABLE_NUMBER_FORMATS}
              selected={NumberFormatUtil.NUMBER_FORMAT_CONFIGS.find(
                (i) => i.format === numberFieldConfig.format
              )}
              onSelectChange={(
                selectedFormat: NumberFormatUtil.NumberFormat
              ) => {
                setNumberFieldConfig({
                  format: selectedFormat.format,
                });
              }}
              fromOption={(option: SelectProps.Option) => {
                return NumberFormatUtil.NUMBER_FORMAT_CONFIGS.find(
                  (i) => i.format === (option.value as FieldConfiguration.NumberFormat)
                );
              }}
              toOption={(numFormat: NumberFormatUtil.NumberFormat) => {
                return {
                  label: numFormat.example,
                  value: numFormat.format.toString(),
                };
              }}
            />
          }
        />

        <SpaceBetween direction="vertical" size="xxs">
          <Header headingTagOverride="h4">Options</Header>
          <SpaceBetween size="s" direction="vertical">
            <Toggle
              checked={showOnLivePage}
              onChange={({ detail }) => {
                setShowOnLivePage(detail.checked);
              }}
            >
              Show column on live page
            </Toggle>
            <Toggle
              checked={numberFieldConfig.displayAsCurrency}
              onChange={({ detail }) => {
                handleDisplayAsCurrency(detail.checked);
              }}
            >
              Show as Currency
            </Toggle>
            {numberFieldConfig.displayAsCurrency && (
              <Box>
                <UIField.CustomField
                  name={"Currency"}
                  description={"Please select currency"}
                  errorText={fieldNumberCurrency.errorText}
                  child={
                    <StaticListSelect.Single<string>
                      data={Object.values(Currency.Code) as string[]}
                      selected={numberFieldConfig.currency}
                      onSelectChange={(selectedCurrency: string) => {
                        setNumberFieldConfig((prev) => ({
                          ...prev,
                          currency: selectedCurrency as Currency.Code,
                        }));
                      }}
                      fromOption={(option: SelectProps.Option) => {
                        return option.value;
                      }}
                      toOption={(currency: string) => {
                        return {
                          label: currency,
                          value: currency,
                        };
                      }}
                    />
                  }
                />
              </Box>
            )}
          </SpaceBetween>
        </SpaceBetween>
      </SpaceBetween>
    </SpaceBetween>
  );
};
