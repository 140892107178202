import {
    AdvancedListItemField,
    AdvancedListSearchItem,
    FieldDefinition,
    FieldDefinitionDataType,
    IdentityRef,
    ItemChoiceOption,
    ItemHyperlink
} from "@amzn/altar-sds-client";
import { FieldConfiguration } from "@amzn/ask-legal-domain";
import { AdvancedListConstants, NumberFormatUtil } from "../utils/advanced-list.constant";
import { DateTimeFormatter } from "../utils/date-time-utils";
import { ExcelFactory } from "./file/excel-factory";

export namespace AdvancedListFactory {
    export function exportAsTemplate(input: {
        fieldDefinitions: FieldDefinition[];
        fieldConfigurationRecord: FieldConfiguration.Record;
    }) {
        const headerCellPairs: Record<string, any>[] = ExportManager.exportAsTemplate(
            input.fieldDefinitions.filter((def) => !def.deprecated),
            input.fieldConfigurationRecord
        );
        return headerCellPairs;
    }

    export function exportItems(input: {
        fieldDefinitions: FieldDefinition[];
        fieldConfigurationRecord: FieldConfiguration.Record;
        items: Pick<AdvancedListSearchItem, "values">[];
    }): {
        headerCellPairs: Record<string, any>[];
    } {
        const fieldDefinitions = input.fieldDefinitions.filter((def) => !def.deprecated);
        const headerCellPairs: Record<string, any>[] = ExportManager.export(
            fieldDefinitions,
            input.fieldConfigurationRecord,
            input.items
        );
        return {
            headerCellPairs
        };
    }

    export function convertToRecords<T extends HeaderCellPair<any>>(arr: T[]): Record<string, any> {
        const record: Record<string, number> = {};
        arr.forEach(obj => {
            record[obj.header] = obj.cell;
        });
        return record;
    }

    export function getDateinYYYYMMDD(value: string): string {
        const serialDateRegex = /^\d+$/;
        if (serialDateRegex.test(value)) {
            return ExcelFactory.getDateFromCell(Number(value)) // Get Date in UTC
                .toISOString().split("T")[0]; // Convert to yyyy-mm-dd
        }
        return DateTimeFormatter.applyDateFormattingOnYYYYMMDD(value, "YYYY-MM-DD");
    }

    interface HeaderCellPair<C> {
        header: string;
        cell: C;
    }

    class ExportManager {
        static exportAsTemplate(
            fieldDefinitions: FieldDefinition[],
            fieldConfigurationRecord: FieldConfiguration.Record
        ): Record<string, any>[] {
            const dataRows: Record<string, any>[] = [];
            const cells: HeaderCellPair<any>[] = [];
            fieldDefinitions.forEach((fieldDef) => {
                const fieldDefinitionHeader = FieldDefinitionHeaderGenerator.generateHeaders(fieldDef, fieldConfigurationRecord[fieldDef.fieldKey]);
                cells.push(...fieldDefinitionHeader);
            });
            dataRows.push(convertToRecords(cells));
            return dataRows;
        }

        static export(
            fieldDefinitions: FieldDefinition[],
            fieldConfigurationRecord: FieldConfiguration.Record,
            items?: Pick<AdvancedListSearchItem, "values">[]
        ): Record<string, any>[] {
            const dataRows: Record<string, any>[] = [];
            if (!items?.length) {
                return dataRows;
            }
            for (const item of items) {
                const cells: HeaderCellPair<any>[] = [];
                // Iterate over field defintion to maintain the order of the columns
                for (const fieldDef of fieldDefinitions) {
                    const itemValue = item.values.find((itemValue) => itemValue.key === fieldDef.fieldKey);
                    const cellsByDataType = FieldDefinitionCellGenerator.generateCells(fieldDef, fieldConfigurationRecord[fieldDef.fieldKey], itemValue);
                    cells.push(...cellsByDataType);
                }
                const record = convertToRecords(cells);
                dataRows.push(record);
            }
            return dataRows;
        }
    }

    class FieldDefinitionHeaderGenerator {
        static generateHeaders(fieldDefinition: FieldDefinition, fieldConfiguration?: FieldConfiguration.Item): HeaderCellPair<any>[] {
            const cells: HeaderCellPair<any>[] = [];
            switch (fieldDefinition.dataType) {
                case FieldDefinitionDataType.string:
                case FieldDefinitionDataType.number:
                    cells.push({
                        header: fieldDefinition.displayName,
                        cell: ""
                    });
                    break;
                case FieldDefinitionDataType.date:
                case FieldDefinitionDataType.boolean:
                case FieldDefinitionDataType.IdentityRef:
                case FieldDefinitionDataType.multiIdentityRef:
                case FieldDefinitionDataType.choice:
                case FieldDefinitionDataType.hyperlink:
                case FieldDefinitionDataType.sequence:
                    cells.push({
                        header: fieldDefinition.displayName,
                        cell: ""
                    });
                    break;
                default:
                    return [];
            }
            return cells;
        }
    }

    class FieldDefinitionCellGenerator {
        static generateCells(
            fieldDefinition: FieldDefinition,
            fieldConfiguration: FieldConfiguration.Item,
            itemValue?: AdvancedListItemField
        ): HeaderCellPair<any>[] {
            const cells: HeaderCellPair<any>[] = [];
            switch (fieldDefinition.dataType) {
                case FieldDefinitionDataType.sequence:
                    cells.push({
                        header: fieldDefinition.displayName,
                        cell: itemValue?.value ?? ""
                    });
                    break;
                case FieldDefinitionDataType.string:
                    cells.push({
                        header: fieldDefinition.displayName,
                        cell: itemValue?.value ?? ""
                    });
                    break;
                case FieldDefinitionDataType.number:
                    const config = fieldConfiguration as FieldConfiguration.Number;
                    cells.push({
                        header: fieldDefinition.displayName,
                        cell: itemValue?.value
                            ? NumberCellFormatter.format((itemValue?.value).toString())
                            : ""
                    });
                    break;
                case FieldDefinitionDataType.date:
                    cells.push({
                        header: fieldDefinition.displayName,
                        cell: itemValue?.value ?? ""
                    });
                    break;
                case FieldDefinitionDataType.boolean:
                    cells.push({
                        header: fieldDefinition.displayName,
                        cell: !!itemValue?.value
                            ? AdvancedListConstants.BOOLEAN_YES_NO_DISPLAY_VALUES.Yes
                            : AdvancedListConstants.BOOLEAN_YES_NO_DISPLAY_VALUES.No
                    });
                    break;
                case FieldDefinitionDataType.choice:
                    const choiceOptions: ItemChoiceOption[] = itemValue?.value as any as ItemChoiceOption[];
                    // Semicolon separated values for each selected choice option
                    cells.push({
                        header: fieldDefinition.displayName,
                        cell: choiceOptions?.map((option) => option.displayValue).join(AdvancedListConstants.EXPORT_MULTI_SELECT_DELIMITER)
                    });
                    break;
                case FieldDefinitionDataType.IdentityRef:
                    cells.push({
                        header: fieldDefinition.displayName,
                        cell: (itemValue?.value as any as IdentityRef)?.id ?? ""
                    });
                    break;
                case FieldDefinitionDataType.multiIdentityRef:
                    const multiIdentityRefs: IdentityRef[] = itemValue?.value as any as IdentityRef[];
                    // Semicolon separated values for each identity ref
                    cells.push({
                        header: fieldDefinition.displayName,
                        cell: multiIdentityRefs?.map((identityRef) => identityRef.id).join(AdvancedListConstants.EXPORT_MULTI_SELECT_DELIMITER)
                    });
                    break;
                case FieldDefinitionDataType.hyperlink:
                    cells.push({
                        header: fieldDefinition.displayName,
                        cell: (itemValue?.value as any as ItemHyperlink)?.href ?? ""
                    });
                    break;
                default:
                    return [];
            }
            return cells;
        }
    }

    class NumberCellFormatter {
        /**
         * SDS stores .001 at the end for the whole number.
         * We need to remove those small decimal while exporting number value
         * @param num string representation of number
         * @returns number
         */
        static format(num: string): number {
            if (NumberFormatUtil.numberRegexForWholeNumbers.test(num)) {
                return NumberFormatUtil.subtractSmallDecimalsFromNumber(Number(num), Number(0.001));
            }
            return Number(num);
        }
    }
}